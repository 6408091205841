import { handleActions } from 'redux-actions';

import {
  getEstimatorListAction,
  createEstimatorDetailsAction,
  updateEstimatorDetailsAction,
  deleteEstimatorDetailsAction,

  getAllLocationAction,
  getLocationListAction,
  createLocationDetailsAction,
  updateLocationDetailsAction,
  deleteLocationDetailsAction,
  clearEstimatorAction,
} from './EstimatorActionConfig';

// reducers
const defaultState = {
  zipLocations: [],
  questions: [],
  configurations: {
    data: [],
    meta: {
      page: 1,
      total: 0,
      last_page: 1,
    },
  },
  locations: {
    data: [],
    meta: {
      page: 1,
      total: 0,
      last_page: 1,
    },
  },
};

const reducer = handleActions({
  [getAllLocationAction](state, { payload }) {
    const { data } = payload;
    return { ...state, zipLocations: data };
  },
  [getEstimatorListAction](state, { payload }) {
    const { data, meta } = payload;
    return { ...state, configurations: { data, meta } };
  },
  [createEstimatorDetailsAction](state, { payload }) {
    const newEstimators = [
      ...state.configurations.data,
      { ...payload },
    ];

    return {
      ...state,
      configurations: {
        ...state.configurations,
        data: newEstimators,
      },
      selectedUser: {},
    };
  },
  [updateEstimatorDetailsAction](state, { params }) {
    const newEstimators = state.configurations.data.map(data => (params.id !== data.id ? data : {
      ...data,
      ...params,
    }));

    return {
      ...state,
      configurations: {
        ...state.configurations,
        data: newEstimators,
      },
      selectedUser: {},
    };
  },
  [deleteEstimatorDetailsAction](state, { params }) {
    const newEstimators = state.configurations.data
      .filter(data => data.id !== params.id);
    return {
      ...state,
      configurations: {
        ...state.configurations,
        data: newEstimators,
      },
    };
  },
  [getLocationListAction](state, { payload }) {
    const { data, meta } = payload;
    return { ...state, locations: { data, meta } };
  },
  [createLocationDetailsAction](state, { payload }) {
    const newEstimators = [
      ...state.locations.data,
      { ...payload },
    ];

    return {
      ...state,
      locations: {
        data: newEstimators,
        ...state.locations,
      },
      selectedUser: {},
    };
  },
  [updateLocationDetailsAction](state, { params }) {
    const newEstimators = state.locations.data.map(data => (params.id !== data.id ? data : {
      ...data,
      ...params,
    }));

    return {
      ...state,
      locations: {
        ...state.locations,
        data: newEstimators,
      },
      selectedUser: {},
    };
  },
  [deleteLocationDetailsAction](state, { params }) {
    const newEstimators = state.locations.data
      .filter(data => data.id !== params.id);
    return {
      ...state,
      locations: {
        ...state.locations,
        data: newEstimators,
      },
    };
  },

  [clearEstimatorAction]() {
    return defaultState;
  },
}, defaultState);

export default reducer;
