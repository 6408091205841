/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY

export const getEstimatorListAction = createAction('FINOVA_ESTIMATOR_GET_LIST');
export const showEstimatorDetailsAction = createAction('FINOVA_ESTIMATOR_SHOW_DETAILS');
export const createEstimatorDetailsAction = createAction('FINOVA_ESTIMATOR_CREATE');
export const updateEstimatorDetailsAction = createAction('FINOVA_ESTIMATOR_UPDATE');
export const deleteEstimatorDetailsAction = createAction('FINOVA_ESTIMATOR_DELETE');
// price
export const updatePriceEstimationAction = createAction('FINOVA_PRICE_ESTIMATION_UPDATE');
export const getEstimationQuestionsAction = createAction('FINOVA_QUESTION_ESTIMATION_GET');
// location
export const getAllLocationAction = createAction('FINOVA_LOCATION_GET_ALL');
export const getLocationListAction = createAction('FINOVA_LOCATION_GET_LIST');
export const showLocationDetailsAction = createAction('FINOVA_LOCATION_SHOW_DETAILS');
export const createLocationDetailsAction = createAction('FINOVA_LOCATION_CREATE');
export const updateLocationDetailsAction = createAction('FINOVA_LOCATION_UPDATE');
export const deleteLocationDetailsAction = createAction('FINOVA_LOCATION_DELETE');
// To All
export const clearEstimatorAction = createAction('FINOVA_ESTIMATOR_CLEAR_DATA');
