export default {
  // prevent redirection if 401 is received
  whitelistUrl: [
    '/login',
  ],
  appVersion: process.env.REACT_APP_VERSION || '1.0.0',
  apiUrl: `${process.env.REACT_APP_API_URL}`,
  previewUrl: process.env.REACT_APP_PREVIEW_URL,
  previewSubmissionPDFURL: process.env.REACT_APP_PREVIEW_PDF_URL,
  env: process.env.NODE_ENV,
  sentry: {
    dsn: process.env.SENTRY_DSN,
    org: process.env.SENTRY_ORG,
    project: process.env.SENTRY_PROJECT,
  },
};
