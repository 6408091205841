import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import './FloatingSelectStyle.scss';
import { Label } from 'reactstrap';

const Control = ({
  isFocused, hasValue, ...props
}) => {
  const { selectProps } = props;
  const controlProps = selectProps.props;

  return (
    <>
      <Label isFloating={isFocused || hasValue} className={`d-flex floating-label align-items-center ${(isFocused || hasValue) ? 'float' : ''}`}>{controlProps.placeholder}</Label>
      <components.Control {...props} />
    </>
  );
};

const FloatingSelect = ({ placeholder, ...rest }) => {
  const selectProps = { props: { placeholder } };
  return <Select {...selectProps} {...rest} components={{ Control }} placeholder="" classNamePrefix="form-select" />;
};

Control.defaultProps = {
  selectProps: {},
};
FloatingSelect.defaultProps = {
  placeholder: 'Select',
};

Control.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  hasValue: PropTypes.bool.isRequired,
  selectProps: PropTypes.object,
};

FloatingSelect.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default FloatingSelect;
