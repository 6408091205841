
import auth from './auth.json';
import accountSettings from './accountSettings.json';
import general from './general.json';
import estimator from './estimator.json';
import menu from './menu.json';
import user from './user.json';
import customForms from './customForms.json';
import condition from './condition.json';

export default {
  user,
  auth,
  accountSettings,
  estimator,
  menu,
  general,
  customForms,
  condition,
};
