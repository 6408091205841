/* eslint-disable no-console */
import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';

import {
  getFormsAction,
  setCustomFormAction,
  addSidebarErrorsAction,
  removeSidebarErrorsAction,
  setPagesAction,
  setQuestionsCountAction,
  savingAction,
  updateFormDateTimeAction,
} from './FormActionConfig';

const { defaultLocale, localeOptions } = constantsConfig;

// reducers
const defaultState = {
  pages: [],
  questionsCount: {},
  customForm: {
    pageIndex: 0,
    sectionIndex: 0,
    questionIndex: 0,
  },
  sidebarErrors: {
    pages: [],
    sections: [],
    questions: [],
  },
  locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0)
    ? localStorage.getItem('currentLanguage')
    : defaultLocale,
  saving: false,
  lastUpdatedAt: null,
};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
  [getFormsAction](state, { payload }) {
    return { ...state, locale: payload };
  },
  [setCustomFormAction](state, { payload }) {
    return {
      ...state,
      customForm: payload,
    };
  },
  [addSidebarErrorsAction](state, { payload }) {
    const newState = { ...state };
    const newSidebarErrors = { ...newState.sidebarErrors };
    // Add the data
    newSidebarErrors[payload.type] = [ ...newSidebarErrors[payload.type], payload.value ];
    return {
      ...state,
      sidebarErrors: newSidebarErrors,
    };
  },
  [removeSidebarErrorsAction](state) {
    return {
      ...state,
      sidebarErrors: {
        pages: [],
        sections: [],
        questions: [],
      },
    };
  },
  [setPagesAction](state, { payload }) {
    return {
      ...state,
      pages: payload,
    };
  },
  [setQuestionsCountAction](state, { payload }) {
    return {
      ...state,
      questionsCount: {
        ...state.questionsCount,
        [payload.id]: payload.count,
      },
    };
  },
  [savingAction](state, { payload }) {
    return {
      ...state,
      saving: payload,
    };
  },
  [updateFormDateTimeAction](state, { payload }) {
    return {
      ...state,
      lastUpdatedAt: payload,
    };
  },
}, defaultState);

export default reducer;
