import moment from 'moment';
import numeral from 'numeral';

// eslint-disable-next-line
import packageJson from '@root/package.json';
const packageName = packageJson.name;

const currencyFormat = (value, format = '0,0.00', currency = '₱', pad = true) => `${currency}${pad ? ' ' : ''}${numeral(value).format(format)}`;

const shortDateFormat = date => moment(date).format('L');

const serverDateFormat = date => moment(date).format('YYYY-MM-DD');

// Replace underscore with spaces and capitalize words
const humanize = (str) => {
  const removedUnderscore = str.split('_');
  const capitalizedWords = removedUnderscore.map(
    word => word.slice(0, 1).toUpperCase() + word.slice(1),
  );
  const joinedWords = capitalizedWords.join(' ');
  // Capitalize words that has slash symbol
  const removeSlashed = joinedWords.split('/');
  const mappedSlashed = removeSlashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  // Capitalize words that has dash symbol
  const joinedMappedWords = mappedSlashed.join('/');
  const removeDashed = joinedMappedWords.split('-');
  const mappedDashed = removeDashed.map(word => word.slice(0, 1).toUpperCase() + word.slice(1));
  return mappedDashed.join('-');
};

const time24HourFormat = date => moment(date).format('HH:mm');

const dateFormat = date => moment(date).format('YYYY-MM-DD');

const timestampToMonthDay = date => moment(date).format('MMM. DD');

const timestampToDate = (date, showYear = false) => {
  const parsedDate = moment(date);
  if (showYear) return parsedDate.format('MMM. D, YYYY');

  const now = moment();
  return now.isSame(date, 'year') ? parsedDate.format('MMM. D') : parsedDate.format('MMM. D, YYYY');
};

const timestampToDayOfWeek = date => moment(date).format('dddd');

const timestampToDateTime = date => moment(date).format('MMMM D, YYYY h:mm a');

const normalizeMobile = (mobile) => {
  let normalizedMobile = mobile;
  if (mobile.startsWith('0')) {
    normalizedMobile = normalizedMobile.replace('0', '');
  } else if (mobile.startsWith('63')) {
    normalizedMobile = normalizedMobile.replace('63', '');
  } else if (mobile.startsWith('+63')) {
    normalizedMobile = normalizedMobile.replace('+63', '');
  }
  return normalizedMobile;
};

const getCurrentVersion = () => localStorage.getItem(`${packageName}-version`);

const isLatestVersion = currentVersion => currentVersion === getCurrentVersion();

const setLatestVersion = version => localStorage.setItem(`${packageName}-version`, version);


const hex = (c) => {
  const v = `0${c.charCodeAt(0).toString(16)}`;
  return `\\x${v.substr(v.length - 2)}`;
};

const stringEscape = s => (s ? s
  .replace(/\\/g, '\\\\')
  .replace(/\n/g, '\\n')
  .replace(/\t/g, '\\t')
  .replace(/\v/g, '\\v')
  .replace(/'/g, "\\'")
  .replace(/"/g, '\\"')
  // eslint-disable-next-line
  .replace(/[\x00-\x1F\x80-\x9F]/g, hex) : s);

export {
  currencyFormat,
  shortDateFormat,
  humanize,
  dateFormat,
  time24HourFormat,
  timestampToMonthDay,
  timestampToDate,
  timestampToDayOfWeek,
  timestampToDateTime,
  normalizeMobile,
  serverDateFormat,
  getCurrentVersion,
  isLatestVersion,
  setLatestVersion,
  stringEscape,
};
