/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';

import LocaleService from '@services/LocaleService';
import AsyncButton from '@shared/buttons/AsyncButton/AsyncButton';

import './ConfirmModalStyle.scss';

const i18nGeneral = LocaleService.getTranslations('general');

export const ConfirmModal = {
  show: () => {},
  hide: () => {},
};

export const useConfirmModal = () => ConfirmModal;

const ConfirmModalComponent = () => {
  const [ showModal, setShowModal ] = useState(false);
  const [ modalProps, setModalProps ] = useState({
    title: '',
    body: '',
    onConfirm: () => {},
  });

  const confirmClose = () => {
    setModalProps({
      title: '',
      body: '',
      onConfirm: () => {},
    });
    setShowModal(false);
  };

  const onConfirm = async () => {
    await modalProps.onConfirm();
    confirmClose();
  };

  useEffect(() => {
    ConfirmModal.show = (data) => {
      console.log('data:', data);
      setModalProps({
        title: data?.title,
        body: data?.body,
        size: data?.size || 'sm',
        showFooter: data?.showFooter,
        isCentered: data?.isCentered,
        onConfirm: data?.onConfirm,
        confirmBtnLabel: {
          title: data?.confirmBtn?.title || i18nGeneral.button.confirm.title,
          name: data?.confirmBtn?.name || i18nGeneral.button.confirm.name,
        },
        cancelBtnLabel: {
          title: data?.cancelBtn?.title || i18nGeneral.button.cancel.title,
          name: data?.cancelBtn?.name || i18nGeneral.button.cancel.name,
        },
      });
      setShowModal(true);
    };
    ConfirmModal.hide = () => confirmClose();
  }, []);

  if (!showModal) return null;

  return (
    <>
      <Modal
        isOpen={showModal}
        toggle={confirmClose}
        contentClassName="confirm-modal-content-style"
        // modalClassName="confirm-modal-style"
        size={modalProps.size}
        centered={modalProps.isCentered}
      >
        <ModalHeader className="confirm-modal-header-style" toggle={confirmClose}>
          <h4>{modalProps.title}</h4>
        </ModalHeader>
        <ModalBody className="confirm-modal-body-style">
          {modalProps.body}
        </ModalBody>
        {modalProps.showFooter && (
          <div className="confirm-modal-footer-style">
            <AsyncButton
              color="primary"
              outline
              className="confirm-modal-cancel-button btn-block h6"
              onClick={confirmClose}
              title={modalProps?.cancelBtnLabel?.title}
            >
              {modalProps?.cancelBtnLabel?.name}
            </AsyncButton>
            <AsyncButton
              color="primary"
              className="btn-block h6"
              onClick={onConfirm}
              title={modalProps?.confirmBtnLabel?.title}
            >
              {modalProps?.confirmBtnLabel?.name}
            </AsyncButton>
          </div>
        )}
      </Modal>
    </>
  );
};

ConfirmModalComponent.defaultProps = {

};

ConfirmModalComponent.propTypes = {

};


export default ConfirmModalComponent;
