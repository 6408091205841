import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';

import { reducer as toastrReducer } from 'react-redux-toastr';
import AuthReducer from '@reducers/auth/AuthReducer';
import LoadingReducer from '@reducers/loading/LoadingReducer';
import MenuReducer from '@reducers/menu/MenuReducer';
import SettingsReducer from '@reducers/settings/SettingsReducer';
import EstimatorReducer from '@reducers/estimator/EstimatorReducer';
import FormReducer from '@reducers/form/FormReducer';

import persistConfig from '@config/redux-persist';

const reducers = combineReducers({
  Auth: persistReducer(persistConfig.auth, AuthReducer),
  Loading: LoadingReducer,
  Menu: MenuReducer,
  Settings: SettingsReducer,
  Estimator: EstimatorReducer,
  Form: FormReducer,
  toastr: toastrReducer,
});

export default reducers;
