import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
// import ClipLoader from "react-spinners/CircleLoader";
import './LoadingStyle.scss';

export const Loading = {
  setLoading: () => null,
};

export const useLoading = () => Loading;

const LoadingRoot = ({
  cover,
}) => {
  const [ show, setShow ] = useState(false);

  useEffect(() => {
    Loading.setLoading = setShow;
  }, []);

  if (!show) return null;
  return (
    <div className={`${cover ? 'full-screen' : 'loading'}`}>
      <Container>
        <Row className="align-items-center">
          <Col>
            <div className="loading" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

LoadingRoot.propTypes = {
  cover: PropTypes.bool.isRequired,
};

export default LoadingRoot;
