/* eslint-disable no-console */
import { handleActions } from 'redux-actions';

import constantsConfig from '@config/constants';

import {
  changeLocaleAction,
  setHideTopNav,
} from './SettingsActionConfig';

const { defaultLocale, localeOptions } = constantsConfig;

// reducers
const defaultState = {
  hideTopNav: false,
  locale: (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0)
    ? localStorage.getItem('currentLanguage')
    : defaultLocale,
};

const reducer = handleActions({
  // getHomeAction = Variable| state, action(payload)
  [changeLocaleAction](state, { payload }) {
    return { ...state, locale: payload };
  },
  [setHideTopNav](state, { payload }) {
    console.log('payload:', payload);
    return {
      ...state,
      hideTopNav: payload,
    };
  },
}, defaultState);

export default reducer;
