import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import ReduxToastr from 'react-redux-toastr';
import * as Sentry from '@sentry/react';
import appConfig from '@config/app';

import CacheBuster from '@shared/utils/CacheBuster';

import { MainWrapper } from '@providers/MainProvider';

import { LoadingBlock } from '@shared/utils';
import { stringEscape } from '@shared/helpers';

import LocaleService from '@services/LocaleService';

import { store, persistor } from './store';

const urlPropagate = new RegExp(`^${stringEscape(appConfig.apiUrl)}`);

Sentry.init({
  dsn: appConfig.dsn,
  environment: appConfig.env,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ 'localhost', urlPropagate ],
  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to
  // 100% while in development and then sample at a lower rate in production.
  replaysSessionSampleRate: 0.1,
  // If you're not already sampling the entire session, change the sample rate
  // to 100% when sampling sessions where errors occur.
  replaysOnErrorSampleRate: 1.0,
});


const i18n = LocaleService.getTranslations('menu');

const LazyAppRouter = lazy(() => import('@app/AppRouter'));
const App = () => (
  <CacheBuster>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider messages={i18n} locale="en">
          <Suspense fallback={<LoadingBlock />}>
            <MainWrapper>
              <LazyAppRouter />
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="top-left"
                getState={state => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
                closeOnToastrClick
              />
            </MainWrapper>
          </Suspense>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </CacheBuster>
);

export default App;
