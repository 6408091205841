/* eslint-disable import/prefer-default-export */
import { createAction } from 'redux-actions';

// Action Declarations
// Suggested Action Name Standard: ORG_MODULE_ACTION_ANY
export const loginAction = createAction('SRS_SELLER_AUTH_LOGIN');
export const logoutAction = createAction('SRS_SELLER_AUTH_LOGOUT');
export const clearUserTokenAction = createAction('SRS_SELLER_USER_CLEAR_TOKEN');
export const getUserDetailsAction = createAction('SRS_AUTH_GET_USER_DETAILS');
export const updateInformationAction = createAction('SRS_AUTH_UPDATE_INFORMATION');
