import { handleActions } from 'redux-actions';

import {
  loginAction,
  logoutAction,
  clearUserTokenAction,
  // forgot password
  getUserDetailsAction,
  updateInformationAction,
} from './AuthActionConfig';

// reducers
const defaultState = {
  accessToken: null,
  user: {},
  errors: {},
};

const reducer = handleActions({
  [loginAction](state, { payload }) {
    return {
      ...state,
      accessToken: payload.access_token,
      user: payload.user,
    };
  },
  [logoutAction]() {
    return defaultState;
  },
  [getUserDetailsAction](state, { payload }) {
    return {
      ...state,
      user: payload,
    };
  },
  [updateInformationAction](state, { params }) {
    const { user } = state;
    user.name = params.name;
    user.email = params.email;
    user.image = params.image;

    return { ...state, user: { ...user } };
  },
  [clearUserTokenAction]() {
    return defaultState;
  },
}, defaultState);

export default reducer;
